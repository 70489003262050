import { useLogout } from "../core/utils/hooks/use-logout";
import { Heading, Text, View, VStack } from "native-base";
import { TouchableHighlight } from "react-native";
import { defaultTheme } from "../../theme/default";
import { Entypo } from "@expo/vector-icons";
import React from "react";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { useFocusEffect } from "@react-navigation/native";
import { events } from "../../constants/analytics.constants";

export const SettingsScreen = () => {
  const { logout } = useLogout();

  const track = useTrackEvent();
  useFocusEffect(() => {
    track(events.userOpenedSettingsScreen);
  });

  return (
    <VStack
      space="3"
      flex="1"
      px="5"
      variant="screen"
      safeAreaTop
      justifyContent="space-between"
    >
      <VStack space="2">
        <Heading size="xl" fontWeight="bold" color="black">
          Settings
        </Heading>
        <Text fontSize="lg">Account Settings</Text>
        <VStack>
          {/* TODO: Extract this component and map out settings if were ever adding more */}
          <TouchableHighlight
            style={{
              display: "flex",
              borderBottomWidth: 1,
              borderBottomColor: defaultTheme.colors.lightGrey,
            }}
            underlayColor={defaultTheme.colors.lightGrey}
            onPress={logout}
          >
            <View
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              px="5"
              py="2"
              w="100%"
            >
              <Text fontSize="md">Logout</Text>
              <Entypo name="chevron-thin-right" size={16} color="black" />
            </View>
          </TouchableHighlight>
        </VStack>
      </VStack>
    </VStack>
  );
};
