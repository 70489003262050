import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateAnalyticsItemDto } from "../services/api-service-sub-services/analytics-api-service";
import dayjs from "dayjs";

const ANALYTICS_SLICE_NAME = "analytics";

export enum Modules {
  NOTIFICATIONS = "notifications",
  COMMENTS = "comments",
  POSTS = "posts",
  INTERACTIONS = "interactions",
}

export type State = {
  queuedItems: CreateAnalyticsItemDto[];
};

const initialState: State = {
  queuedItems: [],
};

export const analyticsSlice = createSlice({
  name: ANALYTICS_SLICE_NAME,
  initialState: initialState,
  reducers: {
    addItemToQueue(
      state: State,
      action: PayloadAction<CreateAnalyticsItemDto>
    ) {
      state.queuedItems = [...state.queuedItems, action.payload];
    },
    addItemsToQueue(
      state: State,
      action: PayloadAction<CreateAnalyticsItemDto[]>
    ) {
      state.queuedItems = [...state.queuedItems, ...action.payload];
    },
    clearItemsInQueue(
      state: State,
      action: PayloadAction<{ currentTimestamp: string }>
    ) {
      state.queuedItems = state.queuedItems.filter(({ meta }) => {
        const metaWithTimestamp = meta as { timestamp: string };

        return dayjs
          .utc(metaWithTimestamp.timestamp)
          .isAfter(action.payload.currentTimestamp);
      });
    },
  },
});
