import React, { useCallback, useMemo } from "react";
import { BotProfileScreenProps } from "./bot-profile.screen";
import { useAppSelector } from "../../store/store";
import { selectUserProfile, selectUserStudyId } from "../../store/user.slice";
import { useStudiesControllerFindAllPostsQuery } from "../../services/api-service-sub-services/studies-api-service";
import { StackScreenProps } from "@react-navigation/stack";
import { Routes } from "../../navigation/routes";
import { selectExperiencePrePopulatedPosts } from "../../store/app.slice";
import {
  useParticipantProfileControllerFindOneQuery,
  useParticipantProfileControllerFollowMutation,
  useParticipantProfileControllerUnfollowMutation,
} from "../../services/api-service-sub-services/profiles-api-service";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";
import { useFocusEffect } from "@react-navigation/native";
import { useBlockBotProfile } from "./hooks/use-block-bot-profile";
import { useUpdateUserProfile } from "./hooks/use-update-user-profile";

type ContainerProps = StackScreenProps<
  AppStackParamList,
  Routes.APP_STACK__BOT_PROFILE
>;
export const BotProfileContainer = (
  Screen: React.FC<BotProfileScreenProps>
) => {
  return function _(props: ContainerProps) {
    const track = useTrackEvent();
    const blockBotProfile = useBlockBotProfile();
    const profileId: string =
      typeof props.route.params.profile === "string"
        ? props.route.params.profile
        : props.route.params.profile._id;

    const studyId = useAppSelector(selectUserStudyId);
    const prePopulatedPosts = useAppSelector(selectExperiencePrePopulatedPosts);
    const postsByThisProfile = prePopulatedPosts.filter(
      (post) => post.profile._id === profileId
    );
    const profile = useAppSelector(selectUserProfile);
    const updateUserProfile = useUpdateUserProfile();

    const { data: paginatedPosts } = useStudiesControllerFindAllPostsQuery(
      {
        id: studyId!,
        page: 1,
        limit: 100,
        profile: profileId,
        isMadeByResearcher: false,
      },
      {
        selectFromResult: ({ data }) => ({ data }),
      }
    );

    // track event where user viewed this profile
    useFocusEffect(() => {
      track(events.userViewedProfile, {
        profileId: profileId,
      });
    });

    const [followProfile] = useParticipantProfileControllerFollowMutation();
    const [unfollowProfile] = useParticipantProfileControllerUnfollowMutation();
    const { data: profileData, refetch } =
      useParticipantProfileControllerFindOneQuery({
        profileId: profileId,
        myProfile: profile?._id!,
      });

    const handleFollowUnFollowButtonPress = async () => {
      if (profileData?.isFollowedByMe) {
        track(events.userUnfollowedProfile, {
          profileId: profileId,
        });
        await unfollowProfile({
          profileId: profileId,
          followDto: {
            myProfile: profile?._id!,
          },
        });
      } else {
        track(events.userFollowedProfile, {
          profileId: profileId,
        });
        await followProfile({
          profileId: profileId,
          followDto: {
            myProfile: profile?._id!,
          },
        });
      }
      refetch();
      await updateUserProfile();
    };

    const posts = useMemo(() => {
      return [...(paginatedPosts?.docs || []), ...postsByThisProfile];
    }, [paginatedPosts, postsByThisProfile]);

    const handleBockButtonPress = useCallback(
      async (profileId: string) => {
        await blockBotProfile(profileId);
        props.navigation.goBack();
      },
      [props.navigation]
    );

    return (
      <Screen
        onBackButtonPress={() => props.navigation.goBack()}
        onBlockButtonPress={handleBockButtonPress}
        posts={posts}
        profile={profileData}
        isFollowedByMe={profileData?.isFollowedByMe}
        onFollowUnFollowButtonPress={handleFollowUnFollowButtonPress}
        displayFollowUnFollowButton={
          !!(profileData && profileId !== profile?._id!)
        }
      />
    );
  };
};
