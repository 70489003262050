import { Button, VStack } from "native-base";
import React from "react";
import { ModalComponentProp } from "react-native-modalfy";
import { ModalStackParams } from "./modal-stack";
import { Dimensions } from "react-native";
import RenderHTML from "react-native-render-html";

type Props = ModalComponentProp<ModalStackParams, void, "InstructionsModal">;
export const InstructionsModal = (props: Props) => {
  const { width } = Dimensions.get("window");
  return (
    <VStack
      variant="screen"
      borderRadius="8"
      px="5"
      py="6"
      space="5"
      width={width * 0.8}
    >
      <VStack space="1">
        <RenderHTML
          source={{
            html: props.modal.params!.instructions,
          }}
          contentWidth={width * 0.8}
          baseStyle={{
            fontFamily: "Roboto",
          }}
          tagsStyles={{
            p: {
              marginBottom: 0,
              paddingBottom: 0,
              fontSize: 16,
              letterSpacing: 0.2,
              fontFamily: "Roboto; sans-serif",
            },
          }}
        />
      </VStack>
      <Button
        variant="primary"
        height="10"
        onPress={() => props.modal.closeModal()}
      >
        Continue
      </Button>
    </VStack>
  );
};
