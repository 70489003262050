import React, { useState } from "react";
import { MagicCodeScreenProps } from "./magic-code.screen";
import { useModal } from "react-native-modalfy";
import { ModalStackParams } from "../core/modals/modal-stack";
import { useAuthControllerAuthenticateWithCodeMutation } from "../../services/api-service-sub-services/authentication-api-service";
import { useAppDispatch } from "../../store/store";
import { userSlice } from "../../store/user.slice";
import { generateUUID } from "../core/utils/uuid.util";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Linking from "expo-linking";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";
import * as Sentry from "sentry-expo";

export const MagicCodeContainer = (Screen: React.FC<MagicCodeScreenProps>) =>
  function _() {
    const track = useTrackEvent();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const { openModal, closeModal } = useModal<ModalStackParams>();

    const [validateMagicCode] = useAuthControllerAuthenticateWithCodeMutation({
      selectFromResult: () => ({}),
    });

    const dispatch = useAppDispatch();
    const openErrorModal = () =>
      openModal("ErrorModal", {
        onContinue: () => {
          closeModal("ErrorModal");
        },
        message:
          "We apologize, but it seems that the Invite Code you entered is invalid. Please double-check the code and try again. If you believe this is an error or need assistance, kindly contact one of our administrators right away for further assistance.",
      });

    return (
      <Screen
        isBusy={isBusy}
        onContactSupportButtonPress={async () => {
          await Linking.openURL("mailto:info@spark-social.com");
        }}
        onVerificationCodeEntry={async (verificationCode: string) => {
          try {
            setIsBusy(true);
            // create and store a fake device UUID since apple does not allow device id tracking
            let deviceUuid = generateUUID();
            const storedUuid = await AsyncStorage.getItem("SMA_DEVICE_ID");
            if (storedUuid) {
              deviceUuid = storedUuid;
            } else {
              await AsyncStorage.setItem("SMA_DEVICE_ID", deviceUuid);
            }

            const authResponse = await validateMagicCode({
              authenticateWithCodeDto: {
                code: verificationCode.toLowerCase(),
                deviceId: deviceUuid,
              },
            }).unwrap();

            dispatch(
              userSlice.actions.userAuthenticated({
                accessToken: authResponse.accessToken,
                refreshToken: authResponse.refreshToken,
                user: authResponse.user,
              })
            );
            track(
              events.userSignedIn,
              {
                participantCode: verificationCode,
              },
              true
            );
          } catch (e: any) {
            track(events.userEnteredInvalidInviteCode, {
              inviteCode: verificationCode,
            });
            Sentry.Native.captureException(e);
            openErrorModal();
          } finally {
            setIsBusy(false);
          }
        }}
      />
    );
  };
