import React, { useState } from "react";
import { PostComponentProps } from "./post.component";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { selectUserProfile, selectUserStudyId } from "../../store/user.slice";
import {
  useStudiesControllerCreateCommentForPostMutation,
  useStudiesControllerFindPostCommentsByPostIdQuery,
  useStudiesControllerUpsertInteractionForPostMutation,
} from "../../services/api-service-sub-services/studies-api-service";
import dayjs from "dayjs";
import { analyticsSlice, Modules } from "../../store/analytics.slice";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { useTreatmentArmExperience } from "../core/hooks/use-treatment-arm-experience";
import { Routes } from "../../navigation/routes";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";
import { selectSimulatedProfiles } from "../../store/app.slice";
import { extractControlledMentions } from "./utils/mentions";

// TODO: REFACTOR THESE
export type PostContainerProps = Pick<
  PostComponentProps,
  | "post"
  | "defaultCommentContent"
  | "isCommentSectionVisibleByDefault"
  | "highlightedCommentId"
  | "onCommentButtonPress"
  | "onNewCommentTextChange"
>;

export type Interaction = {
  [key: string]: number;
};

export const PostContainer = (Component: React.FC<PostComponentProps>) => {
  return function _(props: PostContainerProps) {
    const track = useTrackEvent();
    const navigation = useNavigation();
    const isFocused = useIsFocused();
    const dispatch = useAppDispatch();
    const simulatedProfiles = useAppSelector(selectSimulatedProfiles);

    const studyId = useAppSelector(selectUserStudyId);
    const userProfile = useAppSelector(selectUserProfile);
    const treatmentArmExperience = useTreatmentArmExperience();

    // comments
    const { data: paginatedComments, refetch: refetchComments } =
      useStudiesControllerFindPostCommentsByPostIdQuery(
        { id: studyId as string, postId: props.post._id, limit: 100 },
        // Return only the necessary data since this will be constantly querying
        {
          skip: !isFocused,
          pollingInterval: 900,
          selectFromResult: ({ data }) => ({ data }),
        }
      );

    // mutation for creating new comments
    const [postNewComment, { isLoading: isCommentPosting }] =
      useStudiesControllerCreateCommentForPostMutation({
        selectFromResult: ({ isLoading }) => ({ isLoading }),
      });

    // mutation for saving the interaction pressed by the user
    const [saveInteraction] =
      useStudiesControllerUpsertInteractionForPostMutation({
        selectFromResult: () => ({}),
      });

    const mappedCustomInteractionsToCounterObject =
      treatmentArmExperience.interactions.customInteractions.reduce<{
        [key: string]: number;
      }>((object, item) => {
        object[item.name] = 0;
        return object;
      }, {});

    const [interactionCounters, setInteractionCounters] = useState<Interaction>(
      {
        like: props.post.likes || 0,
        dislike: props.post.dislikes || 0,
        ...mappedCustomInteractionsToCounterObject,
        ...props.post.customInteractionsCount,
      }
    );

    const [currentInteraction, setCurrentInteraction] = useState<string>(
      props.post.interactionByMe ?? "none"
    );

    const [isInteractionsLoading, setIsInteractionLoading] =
      useState<boolean>(false);

    const onInteractionButtonPress = async (interaction: string) => {
      if (!studyId || !userProfile || isInteractionsLoading) {
        return;
      }
      setIsInteractionLoading(true);
      try {
        if (currentInteraction === interaction) {
          setCurrentInteraction("none");
          setInteractionCounters((prev: Interaction) => ({
            ...prev,
            [interaction]: prev[interaction] - 1,
          }));

          dispatch(
            analyticsSlice.actions.addItemToQueue({
              study: studyId,
              participant: userProfile._id,
              module: Modules.INTERACTIONS,
              meta: {
                description: `User removed ${interaction} from post`,
                timestamp: dayjs.utc().toISOString(),
                postId: props.post._id,
              },
            })
          );
        } else {
          setCurrentInteraction(interaction);
          setInteractionCounters((prev: Interaction) => ({
            ...prev,
            [interaction]: prev[interaction] + 1,
          }));
          if (currentInteraction !== "none") {
            setInteractionCounters((prev: Interaction) => ({
              ...prev,
              [currentInteraction]: prev[currentInteraction] - 1,
            }));
          }
          dispatch(
            analyticsSlice.actions.addItemToQueue({
              study: studyId,
              participant: userProfile._id,
              module: Modules.INTERACTIONS,
              meta: {
                description: `User ${interaction}d post`,
                timestamp: dayjs.utc().toISOString(),
                postId: props.post._id,
              },
            })
          );
        }

        const isCustomInteraction =
          treatmentArmExperience.interactions.customInteractions.some(
            (i) => i.name === interaction
          );

        await saveInteraction({
          id: studyId,
          postId: props.post._id,
          createInteractionDto: {
            action: interaction,
            profile: userProfile._id,
            affiliation:
              treatmentArmExperience.interactions.isWithVariants &&
              !isCustomInteraction
                ? treatmentArmExperience.interactions.variant!
                : "",
          },
        }).unwrap();
      } catch (e) {
        console.error("interactions failed to submit", e);
      } finally {
        setIsInteractionLoading(false);
      }
    };

    return (
      <Component
        customInteractions={
          treatmentArmExperience.interactions.customInteractions
        }
        onProfilePress={(profile = props.post.profile) =>
          // @ts-ignore
          navigation.navigate(Routes.APP_STACK__BOT_PROFILE, {
            profile: profile,
          })
        }
        interaction={interactionCounters}
        currentInteraction={currentInteraction}
        highlightedCommentId={props.highlightedCommentId}
        defaultCommentContent={props.defaultCommentContent}
        isCommentSectionVisibleByDefault={
          props.isCommentSectionVisibleByDefault
        }
        isCommentPosting={isCommentPosting}
        post={props.post}
        isInteractionsLoading={isInteractionsLoading}
        comments={paginatedComments?.docs || []}
        onCommentButtonPress={props.onCommentButtonPress}
        onNewCommentTextChange={props.onNewCommentTextChange}
        onInteractionButtonPress={onInteractionButtonPress}
        onPostCommentButtonPress={async (content: string) => {
          try {
            if (!studyId || !userProfile) {
              return;
            }

            const { taggedProfiles, contentWithOrWithoutMentions } =
              extractControlledMentions(content, simulatedProfiles);

            await postNewComment({
              id: studyId,
              postId: props.post._id,
              createCommentDto: {
                content: contentWithOrWithoutMentions,
                date: dayjs().toISOString(),
                profile: userProfile._id,
                mediaUrls: [],
                taggedProfiles: taggedProfiles,
              },
            }).unwrap();
            refetchComments();
            track(events.userCreatedComment);
          } catch (e: any) {
            track(events.userUnableToCreateComment);
            console.log("ERROR", e as Error);
          }
        }}
        onShowCommentButtonPress={() => {
          if (!userProfile || !studyId || !paginatedComments) {
            return;
          }
          track(events.userOpenedCommentsThead, {
            postId: props.post._id,
          });
          dispatch(
            analyticsSlice.actions.addItemToQueue({
              participant: userProfile._id,
              module: Modules.COMMENTS,
              study: studyId,
              meta: {
                description: "User opened comment thread",
                timestamp: dayjs.utc().toISOString(),
                postId: props.post._id,
                // TODO: Handle pagination of comments in the future
                commentIds: paginatedComments?.docs.map(({ _id }) => _id) || [],
              },
            })
          );
        }}
      />
    );
  };
};
