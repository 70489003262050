import React, { useCallback, useState } from "react";
import { NewPostComponentProps } from "./new-post.screen";
import { useUsersControllerGetProfileQuery } from "../../services/api-service-sub-services/users-api-service";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { selectUserId, selectUserStudyId } from "../../store/user.slice";
import { useStudiesControllerCreatePostMutation } from "../../services/api-service-sub-services/studies-api-service";
import {
  enhancedStudiesApiService,
  STUDY_TAGS,
} from "../../services/api-service-sub-services/enhanced-studies-api-service";
import * as ImagePicker from "expo-image-picker";
import {
  MediaControllerUploadRequest,
  useMediaControllerUploadMutation,
} from "../../services/api-service-sub-services/media-api-service";
import { useToastErrorMessage } from "../core/hooks/use-toast-error-message";
import { StackScreenProps } from "@react-navigation/stack";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { Routes } from "../../navigation/routes";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";
import { extractControlledMentions } from "./utils/mentions";
import { selectSimulatedProfiles } from "../../store/app.slice";

export type NewPostContainerProps = StackScreenProps<
  AppStackParamList,
  Routes.APP_STACK__CREATE_POST
>;

export const NewPostContainer = (Screen: React.FC<NewPostComponentProps>) => {
  return function (props: NewPostContainerProps) {
    const track = useTrackEvent();
    const toastError = useToastErrorMessage();
    const userId = useAppSelector(selectUserId);
    const studyId = useAppSelector(selectUserStudyId);
    const { data: profile } = useUsersControllerGetProfileQuery(
      { id: userId! },
      { selectFromResult: ({ data }) => ({ data }) }
    );
    const [post] = useStudiesControllerCreatePostMutation({
      selectFromResult: () => ({}),
    });
    const [uploadPhoto] = useMediaControllerUploadMutation();
    const dispatch = useAppDispatch();
    const [image, setImage] = useState<string>("");
    const simulatedProfiles = useAppSelector(selectSimulatedProfiles);

    const pickImage = async () => {
      const permissionResult =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (
        permissionResult.accessPrivileges === "all" ||
        permissionResult.accessPrivileges === "limited" ||
        permissionResult.granted
      ) {
        const result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: false,
          quality: 1,
        });
        if (!result.canceled) {
          setImage(result.assets[0].uri);
          track(events.userAddedImageToPost);
        }
      }
    };

    const handleOnPostButtonPress = async (content: string) => {
      try {
        if (!studyId) {
          return;
        }

        let imageURL;
        if (image.length > 0) {
          const path = "user-generated";
          const filename = image.split("/").pop() as string;
          const extension = filename.split(".").pop();
          const formData = new FormData();
          // @ts-ignore
          formData.append("file", {
            // @ts-ignore
            name: filename,
            type: `image/${extension}`,
            uri: image,
          });
          formData.append("path", path);

          const result = await uploadPhoto({
            body: formData as MediaControllerUploadRequest["body"],
          }).unwrap();

          imageURL = result.url;
        }

        // extract the mentions from the library's supported format
        // which is @[username](id) to @[username]
        const { taggedProfiles, contentWithOrWithoutMentions } =
          extractControlledMentions(content, simulatedProfiles);

        await post({
          id: studyId,
          createPostDto: {
            profile: profile!._id,
            content: contentWithOrWithoutMentions,
            date: new Date().toISOString(),
            mediaUrls: imageURL ? [imageURL] : [],
            taggedProfiles: taggedProfiles,
          },
        }).unwrap();
        track(events.userCreatedPost);
        dispatch(
          enhancedStudiesApiService.util.invalidateTags([STUDY_TAGS.POSTS])
        );
      } catch (e) {
        track(events.userUnableToCreatePost);
        toastError({
          message:
            "We are unable to do this at the moment. Please try again later.",
        });
      } finally {
        setImage("");
      }
    };

    const handleOnRemoveButtonPress = useCallback(() => {
      setImage("");
    }, []);

    return (
      <Screen
        profile={profile}
        image={image}
        onSelectPhotoButtonPress={pickImage}
        onRemovePhotoButtonPress={handleOnRemoveButtonPress}
        onPostButtonPress={handleOnPostButtonPress}
        onCancelButtonPress={() => props.navigation.goBack()}
      />
    );
  };
};
